import {
  ChangeSet,
  Column,
  CustomTreeData,
  EditingState,
  Sorting,
  SortingState,
  TreeDataState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableBandHeader,
  TableColumnResizing,
  TableFixedColumns,
  TableHeaderRow,
  TableInlineCellEditing,
  TableTreeColumn,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { Paper } from '@material-ui/core';
import equal from 'deep-equal';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { DataConverters } from '../shared/dataConverters';
import {
  CellPropsWithStyle,
  ColumnWidth,
  DataCellPropsWithStyle,
  DxTableRow,
  ReportProps,
} from '../shared/dataTypes';
import { nextRequestId } from '../shared/utils';
import './dx-grid.scss';
import { getAdjustedColumnWidths, getChangedWidthValues, getColumnBands } from './gridHelpers';
import {
  ReactiveDataGridBandHeaderCell,
  ReactiveDataGridCell,
  ReactiveDataGridExpandButton,
} from './ReactiveDataGridCell';
import ReactiveDataGridHeader from './ReactiveDataGridHeader';

const Root = (props: Grid.RootProps) => <Grid.Root {...props} style={{ height: `100%` }} />;

const columnsAreEqual = (columnsFromProps: Column[], columnWidths: ColumnWidth[]) =>
  equal(
    columnsFromProps?.map(c => c.name),
    columnWidths?.map(c => c.columnName),
  );

const ReactiveDataGrid: React.FC<ReportProps> = props => {
  const converted = DataConverters.DX_TABLE(props.reportRawData);

  const [precisionControlColumnName, setPrecisionControlColumnName] = useState<string | null>(null);

  const sortColumns: Sorting[] = props.sort?.map(c => ({
    // we use header.name to store the column hash
    columnName: converted.headers.find(h => h.name === c.columnHash.toString()).name,
    direction: c.direction,
  }));

  interface DxTableRowMap {
    [parentId: number]: DxTableRow[];
  }

  const rowMap = useMemo(() => {
    let returnable: DxTableRowMap = {};
    converted.rows.forEach(row => {
      if (returnable[row.parentId]) returnable[row.parentId].push(row);
      else returnable[row.parentId] = [row];
    });
    return returnable;
  }, [converted.rows]);

  const groupingHeader: { name: string } = converted.groupingHeader;

  // Putting this in a useEffect to avoid "Cannot update a component from inside the function
  // body of a different component" error.
  useEffect(() => {
    if (!columnsAreEqual(converted.headers, props.columnWidths))
      // Columns have changed
      props.setDxTableColumnWidths?.(
        props.sequenceId,
        converted.headers.map(
          cfp =>
            props.columnWidths?.find(cw => cfp.name === cw.columnName) || {
              columnName: cfp.name,
            },
        ),
      );
  });

  const wrapperRef = useRef<HTMLElement | null>(null);
  const scrollingDivRef = useRef<HTMLDivElement>(null);

  const { onScrollBeginning, onScrollEnd } = props;
  useEffect(() => {
    if ((onScrollBeginning || onScrollEnd) && wrapperRef.current) {
      scrollingDivRef.current = wrapperRef.current.querySelector('div')?.querySelector('div');

      const handleScrollEvent = () => {
        if (scrollingDivRef.current.scrollTop === 0) {
          onScrollBeginning?.();
        } else if (
          scrollingDivRef.current.scrollTop + scrollingDivRef.current.clientHeight >=
          scrollingDivRef.current.scrollHeight
        ) {
          onScrollEnd?.();
        }
      };

      if (scrollingDivRef.current) {
        scrollingDivRef.current.addEventListener('scroll', handleScrollEvent);
      }

      return () => {
        if (scrollingDivRef.current) {
          scrollingDivRef.current.removeEventListener('scroll', handleScrollEvent);
        }
      };
    }

    // TypeScript demands I have a return statement here bc I have a return statement above.
    return undefined;
  }, [onScrollBeginning, onScrollEnd]);

  // Return a random number between min/max
  const getRandom = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; // The maximum is exclusive and the minimum is inclusive
  };

  const sparkLinesRandomSeedData: Array<Array<{ uv: number }>> = [];
  for (let i = 0; i < converted.rows.length; i++) {
    const items = new Array(25)
      .fill(undefined)
      .map((_, i) => i + 1)
      .map(() => ({
        uv: getRandom(0, 4000),
      }));

    sparkLinesRandomSeedData.push(items);
  }

  const [sparkLinesItems] = useState(sparkLinesRandomSeedData);

  const colExtensions: Table.ColumnExtension[] = converted.headers.map(header => ({
    columnName: header.title,
    align: header.title === groupingHeader.name ? 'left' : 'center',
  }));

  const getColumnIdByName = (name: string) => converted.headers.findIndex(c => c.name === name);

  type FocusableCellProps = { expandable?: boolean } & DataCellPropsWithStyle;

  const FocusableCell: FC<FocusableCellProps> = ({ expandable, ...dataCellProps }) => (
    <ReactiveDataGridCell
      expandable={expandable}
      dataCellProps={dataCellProps}
      sparkLinesItems={sparkLinesItems}
      onClick={props.onElementClick}
      getColumnIdByName={getColumnIdByName}
      pendingRequests={props.pendingRequests}
      sequenceId={props.sequenceId}
      showGrandTotalRow={props.showGrandTotalRow}
      cellIsAwaitingEditResponse={props.cellIsAwaitingEditResponse}
      selectedElements={props.selectedElements}
    />
  );

  const TableHeaderContent = (contentProps: TableHeaderRow.ContentProps) => (
    <ReactiveDataGridHeader
      {...{ contentProps }}
      clearColumnWidth={() =>
        props.setDxTableColumnWidths(
          props.sequenceId,
          props.columnWidths.map(c => ({
            ...c,
            width: c.columnName === contentProps.column.name ? null : c.width,
          })),
        )
      }
      clearAllColumnWidths={() =>
        props.setDxTableColumnWidths(
          props.sequenceId,
          props.columnWidths.map(c => ({ ...c, width: null })),
        )
      }
      updatePrecision={props.updatePrecision}
      precisionControlOpen={precisionControlColumnName === contentProps.column.name}
      setPrecisionControlOpen={open =>
        setPrecisionControlColumnName(open ? contentProps.column.name : null)
      }
    >
      {contentProps.children}
    </ReactiveDataGridHeader>
  );

  const commitChanges = (changeSet: ChangeSet) => {
    const { added, changed, deleted } = changeSet;
    if (added) {
      console.log('added', added);
    }
    if (changed) {
      const rowId = Number(Object.keys(changed)[0]);

      if (changed[rowId]) {
        // User made an actual change and didn't click out or esc or enter with unchanged value
        const colName = Object.keys(changed[rowId])[0];
        const colIndex = getColumnIdByName(colName);

        props.applyCellEdit(
          props.sequenceId,
          `seq-${props.sequenceId}-req-${nextRequestId()}-${new Date().getTime()}`,
          {
            rowIndex: rowId,
            colIndex,
            newValue: changed[rowId][colName],
          },
        );
      }
    }
    if (deleted) {
      console.log('deleted', deleted);
    }
  };

  const initialExpandedRowIds: number[] = converted.expandedRowIds;

  const CustomBandHeader: FC<CellPropsWithStyle> = bandProps => (
    <TableBandHeader.Cell
      style={{ padding: 0, height: 'inherit', backgroundColor: '#fff' }}
      {...bandProps}
    >
      <props.bandHeaderComponent {...bandProps} />
    </TableBandHeader.Cell>
  );

  const adjustedColumnWidths = getAdjustedColumnWidths(props.columnWidths || [], converted);
  const columnWidths = props.columnWidths ? adjustedColumnWidths : [];

  return (
    <Paper className='dx-grid-container' style={props.style} ref={wrapperRef}>
      {/* While we wait for the columnWidths useEffect to run, columnWidths and converted.headers
            may have different columns, which will throw errors if we let Grid render. Expect that
            if this function returns false, a new render is about to happen with the function
            returning true, and the Grid will show up. */}
      {columnsAreEqual(converted.headers, props.columnWidths) && (
        <Grid
          rows={converted.rows}
          columns={converted.headers}
          getRowId={row => row.rowHashCode}
          rootComponent={Root}
        >
          <TreeDataState
            expandedRowIds={props.expandedRowIds || initialExpandedRowIds}
            onExpandedRowIdsChange={(expandedRowIds: string[]) =>
              props.setExpandedRowIds(props.sequenceId, expandedRowIds)
            }
          />
          <SortingState sorting={sortColumns} onSortingChange={props.onSortingChange} />
          <CustomTreeData getChildRows={row => rowMap[row?.id || 0]} />
          <EditingState onCommitChanges={commitChanges} />
          <VirtualTable
            height='auto'
            columnExtensions={colExtensions}
            estimatedRowHeight={24}
            cellComponent={FocusableCell}
          />

          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={nextColumnWidths => {
              props.setDxTableColumnWidths?.(
                props.sequenceId,
                nextColumnWidths.map(
                  getChangedWidthValues(props.columnWidths, adjustedColumnWidths),
                ),
              );
            }}
          />
          <TableHeaderRow
            showSortingControls={props.showSortingControls}
            contentComponent={TableHeaderContent}
          />

          {/* Use TableTreeColumn only if the data is actually tree data */}
          {new Set(converted.rows.map(r => r.parentId)).size > 1 && (
            <TableTreeColumn
              for={groupingHeader.name}
              cellComponent={props => <FocusableCell {...props} expandable />}
              expandButtonComponent={ReactiveDataGridExpandButton}
            />
          )}

          <TableBandHeader
            columnBands={
              props.bandHeaderComponent ? getColumnBands(converted.headers) : converted.colBands
            }
            cellComponent={
              props.bandHeaderComponent ? CustomBandHeader : ReactiveDataGridBandHeaderCell
            }
          />

          <TableFixedColumns leftColumns={props.fixedColumnsLeft} />

          <TableInlineCellEditing startEditAction='doubleClick' selectTextOnEditStart={true} />
        </Grid>
      )}
    </Paper>
  );
};

export default ReactiveDataGrid;
