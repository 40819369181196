import { Checkbox, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowRight } from '@material-ui/icons';
import { CSSProperties, FC } from 'react';
import { TreeDropdownItem } from './TreeDropdown';

interface Props {
  item: TreeDropdownItem;
  checked: boolean;
  indeterminate: boolean;
  style: CSSProperties;
  setChecked: (checked: boolean) => void;
  expandable: boolean;
  expanded?: boolean;
  setExpanded?: () => void;
}

const TreeItem: FC<Props> = props => {
  const arrowStyle: CSSProperties = { visibility: props.expandable ? 'visible' : 'hidden' };

  const Arrow = props.expanded ? ArrowDropDown : ArrowRight;

  return (
    <div
      className='tree-item'
      style={{ ...props.style, marginLeft: `${props.item.indentLevel}em` }}
    >
      <Arrow style={arrowStyle} onClick={props.setExpanded} />

      <Checkbox
        color='primary'
        checked={props.checked || false}
        indeterminate={props.indeterminate}
        onChange={e => props.setChecked(e.target.checked)}
      />
      <Typography>{props.item.name}</Typography>
    </div>
  );
};

export default TreeItem;
