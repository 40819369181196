import { FC, useState } from 'react';
import { TableStateContext } from './context';

const TableStateProvider: FC = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <TableStateContext.Provider
      value={{
        state: {
          searchTerm,
        },

        dispatch: {
          setSearchTerm,
        },
      }}
    >
      {children}
    </TableStateContext.Provider>
  );
};

export default TableStateProvider;
