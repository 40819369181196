export enum XYChartType {
  XY_LINE,
  XY_LINE_ONLY,
  XY_DOTS_ONLY,
  XY_STACKED_BAR,
  XY_SPLINE_LINE,
  XY_SPLINE_LINE_ONLY,
  XY_BAR,
}

export enum NodeType {
  FOLDER = 'FOLDER',
  TEMPLATE = 'TEMPLATE',
  ROOT = 'ROOT',
  TOTAL = 'TOTAL',
  WORKSPACE = 'WORKSPACE',
  REPORT = 'REPORT',
  HEADER = 'header',
  BUCKET = 'bucket',
  DETAIL = 'detail',
  SANDBOX = 'sandbox',
}

export enum RowField {
  ROW_HASH = 0,
  COL_HASH = 1,
  VAL = 2,
  DIFF_VAL = 3,
  FORMATTED_VAL = 4,
  FORMATTED_DIFF_VAL = 5,
  BG_COLOR = 6,
  FG_COLOR = 7,
  IS_EXPANDED = 8,
  IS_EDITABLE = 9,
}

export enum ReportRequestStatus {
  GENERATE = 'generate',
  REGENERATING = 'regenerating',
  REFRESHING = 'refreshing',
  RECONNECTING = 'reconnecting',
  UPDATING = 'updating',
  PENDING = 'pending',
}

export enum ReportUpdateHighlightMode {
  REPORT_CHANGE = 'REPORT_CHANGE',
  CELL_CHANGE = 'CELL_CHANGE',
}

// cf. `com.armanta.rptgen.ReportData`
export enum Modifier {
  PORT,
  BENCH,
  DIFF,
  BENCH2,
  DIFF2,
}

export const LIGHT_BORDER = '1px solid #e6e8e9';
