import { Popover, PopoverProps, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import UndoIcon from '@material-ui/icons/Undo';
import { FC } from 'react';
import IconAction from '../IconAction/IconAction';
import { PrecisionBounds } from './model';
import './precision-control.scss';
import PrecisionControlButton from './PrecisionControlButton';

export interface Props extends PrecisionBounds {
  open: boolean;
  onClose: () => void;
  PopoverProps: Partial<PopoverProps>;
  precision: number;
  setPrecision: (precision: number) => void;
}

const PrecisionControl: FC<Props> = ({
  open,
  onClose,
  PopoverProps,
  precision,
  initialPrecision = 0,
  minPrecision = -Infinity,
  maxPrecision = Infinity,
  setPrecision,
}) => {
  const increment = () => precision < maxPrecision && setPrecision(precision + 1);
  const decrement = () => precision > minPrecision && setPrecision(precision - 1);
  const revert = () => precision !== initialPrecision && setPrecision(initialPrecision);

  return (
    <Popover {...PopoverProps} {...{ open, onClose }} classes={{ paper: 'precision-control' }}>
      <div className='precision-control-header'>
        <Typography className={'precision-control-label'}>Precision Control</Typography>
      </div>

      <div className='precision-control-content'>
        <PrecisionControlButton
          onClick={increment}
          icon={AddCircleIcon}
          // disabled={precision === maxPrecision}
        />
        <PrecisionControlButton
          onClick={decrement}
          icon={RemoveCircleIcon}
          // disabled={precision === minPrecision}
        />

        <div className='precision-control-vr' />

        <IconAction
          label='Revert'
          icon={UndoIcon}
          onClick={revert}
          // disabled={precision === initialPrecision}
        />
      </div>
    </Popover>
  );
};

export default PrecisionControl;
