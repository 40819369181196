import { TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import { ColumnWidth, DxGridData, SortableColumn } from '../shared/dataTypes';

export const getAdjustedColumnWidths = (columnWidths: ColumnWidth[], converted: DxGridData) =>
  converted.headers.map(h => {
    // Identify the longest string in the column, including consideration of the header.
    // The header needs a little more space due to the sort button, hence the 'ww'
    // to cause that measurement to be a bit longer.
    const longest = converted.rows.reduce(
      (acc, cur) => (!cur[h.name] || acc.length > cur[h.name].length ? acc : cur[h.name]),
      `ww${h.title}`,
    );

    // If the column width is defined in the report definition, it'll be in props.columnWidths
    // and we need to keep it. Otherwise, run the measurement on the longest value from before.
    // Measurement is an expensive calculation, so we run it only on the longest value
    // in each column.
    return {
      columnName: h.name,
      width:
        Number(columnWidths.find(cw => cw.columnName === h.name)?.width) ||
        (getTextWidth(longest) + 18) * 1.2,
    };
  });

const context = document.createElement('canvas').getContext('2d');
context.font = '12px Source Sans Pro';

const getTextWidth = (text: string): number => context.measureText(text).width;

/*
DX doesn't tell us which column's width the user changed; rather, it gives us widths for
all columns. This function determines which column's width actually changed.
*/
export const getChangedWidthValues = (
  originalColumnWidths: ColumnWidth[],
  adjustedColumnWidths: TableColumnWidthInfo[],
) => (incomingWidth: TableColumnWidthInfo) => {
  const originalWidth = originalColumnWidths.find(cw => cw.columnName === incomingWidth.columnName);
  const adjustedWidth = adjustedColumnWidths.find(cw => cw.columnName === incomingWidth.columnName);

  return originalWidth?.width === incomingWidth.width ||
    (!originalWidth.width && incomingWidth.width === adjustedWidth.width)
    ? originalWidth
    : { ...incomingWidth, width: Number(incomingWidth.width) };
};

export const getColumnBands = (headers: SortableColumn[]) =>
  headers.map(h => ({
    title: `${Math.random()}`, // DX requires each header to have a different title
    children: [{ columnName: h.name }],
  }));
