import { Link, Typography } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';
import { FC } from 'react';
import { BaseButtonProps } from '../../shared/dataTypes';
import './icon-action.scss';

export interface Props extends BaseButtonProps {
  icon: SvgIconComponent;
  caption?: string;
}

const IconAction: FC<Props> = props => (
  <Link
    className='icon-action'
    component='button'
    onClick={props.onClick}
    disabled={props.disabled}
    color={props.disabled ? 'initial' : 'primary'}
    underline={props.disabled ? 'none' : 'hover'}
  >
    <props.icon className='icon-action-icon' onClick={props.onClick} />
    <div className='quick-action-content'>
      <span>{props.label}</span>

      {props.caption && (
        <Typography className='icon-action-caption' onClick={props.onClick}>
          {props.caption}
        </Typography>
      )}
    </div>
  </Link>
);

export default IconAction;
