import { Moment } from 'moment';
import { FC } from 'react';

interface Props {
  moment: Moment;
  runExists: boolean;
}

const DatePickerDay: FC<Props> = props => (
  <div className='date-picker-day'>
    <div className='day-of-month'>{props.moment.format('D')}</div>
    <div className='run-exists-dot' style={{ visibility: props.runExists ? 'revert' : 'hidden' }} />
  </div>
);

export default DatePickerDay;
