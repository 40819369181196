import { Menu } from '@material-ui/core';
import { FC } from 'react';
import { CascadingMenuItem } from './model';
import NestedMenuItem from './NestedMenuItem';

interface Props {
  anchorEl: null | HTMLElement;
  setAnchorEl: (value: React.SetStateAction<HTMLElement>) => void;
  items: CascadingMenuItem[];
}

const CascadingMenuRoot: FC<Props> = props => (
  <Menu
    className='hide-in-pdf'
    anchorEl={props.anchorEl}
    getContentAnchorEl={null}
    open={!!props.anchorEl}
    onClose={() => props.setAnchorEl(null)}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
  >
    {props.items.map(
      (item, key) =>
        item && (
          <div key={key}>
            {/* There's an error about refs if you don't put this inside a div */}
            <NestedMenuItem
              item={item}
              mainMenuOpen={!!props.anchorEl}
              closeMainMenu={() => props.setAnchorEl(null)}
            />
          </div>
        ),
    )}
  </Menu>
);

export default CascadingMenuRoot;
