import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import { ArrowRight, Check } from '@material-ui/icons';
import { FC, useRef, useState } from 'react';
import { isCascadingMenuLeafItem } from './isCascadingMenuLeafItem';
import { CascadingMenuItem } from './model';

interface Props {
  item: CascadingMenuItem;
  mainMenuOpen: boolean;
  closeMainMenu: () => void;
}

const NestedMenuItem: FC<Props> = props => {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const nestedMenuRef = useRef(null);

  const handleMouseEnter = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSubMenuOpen(true);
    nestedMenuRef.current.style.backgroundColor = '#dddddd';
  };

  const handleMouseLeave = () => {
    setSubMenuOpen(false);
    nestedMenuRef.current.style.backgroundColor = 'white';
  };

  const handleParentClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSubMenuOpen(a => !a);
  };

  const handleLeafClick = () => {
    if (isCascadingMenuLeafItem(props.item)) {
      // should be true every time this function is called
      props.item?.onClick();
      props.closeMainMenu();
    }
  };

  if (isCascadingMenuLeafItem(props.item))
    return (
      <MenuItem onClick={handleLeafClick} disabled={props.item.disabled}>
        <Check
          style={{
            fontSize: '12px',
            marginRight: '5px',
            visibility: props.item.selected ? 'visible' : 'hidden',
          }}
        />
        {props.item.icon}
        {props.item.tooltip ? (
          <Tooltip title={props.item.tooltip}>
            <div>{props.item.text}</div>
          </Tooltip>
        ) : (
          props.item.text
        )}
      </MenuItem>
    );
  else
    return (
      <MenuItem
        ref={nestedMenuRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        disabled={props.item.disabled}
        onClick={handleParentClick}
        style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '17px' }}
      >
        {props.item.icon}
        {props.item.text}
        <ArrowRight />
        <Menu
          // Set pointerEvents to none to prevent menu from capturing events meant for child elements
          style={{ pointerEvents: 'none' }}
          PaperProps={{ style: { pointerEvents: 'auto' } }}
          anchorEl={nestedMenuRef.current}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={subMenuOpen && props.mainMenuOpen}
          onClose={() => setSubMenuOpen(false)}
        >
          <div>
            {/* There are errors about refs if you don't put this in a div */}
            {props.item.children?.map((item, key) => (
              <NestedMenuItem
                item={item}
                key={key}
                mainMenuOpen={props.mainMenuOpen}
                closeMainMenu={props.closeMainMenu}
              />
            ))}
          </div>
        </Menu>
      </MenuItem>
    );
};

export default NestedMenuItem;
