import { Moment } from 'moment';
import { FC, useEffect, useState } from 'react';
import DatePickerButtons from './DatePickerButtons';
import getDefaultDateContextRun from './getDefaultDateContextRun';
import { DateContexts, FullDateContextItem } from './model';
import RadioRuns from './RadioRuns';
import ReportDatesPopover from './ReportDatesPopover';
import SingleDatePicker from './SingleDatePicker';

interface Props {
  selectedDateContext: FullDateContextItem | null;
  onClick?: () => void;
  onApply: (item: FullDateContextItem) => void;
  disabled?: boolean;
  variant?: 'standard' | 'outlined';
  size?: 'small' | 'medium';
  hideLabel?: boolean;
  showRuns: boolean; // true iff isAwa is true
  dateContexts: DateContexts;
  defaultDate: string;
  runExists: (m: Moment) => boolean;
}

const SingleDateRunPicker: FC<Props> = props => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedRun, setSelectedRun] = useState('');

  const { date, id } = props.selectedDateContext ?? {};
  const { dateContexts } = props;
  useEffect(() => {
    if (anchorEl) {
      if (selectedDate !== date) {
        // set run to default when changing date
        setSelectedRun(getDefaultDateContextRun(dateContexts?.[selectedDate]));
      } else {
        // set run to the current run if selecting the selected date
        setSelectedRun(id);
      }
      if (selectedDate === '') {
        // Set state to defaults when popover is opened
        setSelectedDate(date);
      }
    }
  }, [anchorEl, date, id, selectedDate, dateContexts]);

  const getTextFieldValue = () => {
    const { date, id } = props.selectedDateContext ?? {};
    return date ? (id ? `${date} (${id})` : date) : '';
  };

  return (
    <ReportDatesPopover
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      onClick={props.onClick}
      textFieldValue={getTextFieldValue()}
      disabled={props.disabled}
      variant={props.variant}
      size={props.size}
      hideLabel={props.hideLabel}
    >
      <div className='calendar'>
        <SingleDatePicker
          date={selectedDate}
          initDate={props.defaultDate}
          onDateChange={setSelectedDate}
          runExists={props.runExists}
        />
      </div>

      {props.showRuns && (
        <RadioRuns
          disabled={props.disabled}
          items={dateContexts?.[selectedDate]?.map(d => d.id) || []}
          selectedDate={selectedDate}
          selectedRun={selectedRun}
          setSelectedRun={setSelectedRun}
        />
      )}

      <DatePickerButtons
        onApply={() => {
          props.onApply({ date: selectedDate, id: selectedRun });
          setAnchorEl(null);
        }}
        onCancel={() => setAnchorEl(null)}
      />
    </ReportDatesPopover>
  );
};

export default SingleDateRunPicker;
