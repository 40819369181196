import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { FC, useState } from 'react';
import { CascadingMenuItem, CascadingMenuRoot } from '../components/CascadingMenu';
import { ReportPrecisionUpdate, SortableColumn } from '../shared/dataTypes';
import { isColumnTypeFloat } from '../shared/utils';
import PrecisionControl from './PrecisionControl/PrecisionControl';

interface Props {
  contentProps: TableHeaderRow.ContentProps;
  clearColumnWidth: () => void;
  clearAllColumnWidths: () => void;
  precisionControlOpen: boolean;
  setPrecisionControlOpen: (open: boolean) => void;
  updatePrecision?: (update: ReportPrecisionUpdate) => void;
}

const ReactiveDataGridHeader: FC<Props> = props => {
  const [mouseOver, setMouseOver] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [precisionControlAnchorEl, setPrecisionControlAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const column = props.contentProps.column as SortableColumn;
  const columnCellsMenuItems: CascadingMenuItem[] = [
    ...(props.updatePrecision && isColumnTypeFloat(column.type)
      ? [
          {
            id: 'Numerical Precision',
            text: 'Numerical Precision...',
            onClick: () => props.setPrecisionControlOpen(true),
          },
        ]
      : []),
  ];

  const menuItems: CascadingMenuItem[] = [
    {
      id: 'Column Width',
      text: 'Column Width',
      children: [
        {
          id: 'Auto Fit',
          text: 'Auto Fit',
          children: [
            {
              id: 'Current Column',
              text: 'Current Column',
              onClick: props.clearColumnWidth,
            },
            {
              id: 'All Columns',
              text: 'All Columns',
              onClick: props.clearAllColumnWidths,
            },
          ],
        },
      ],
    },

    ...(columnCellsMenuItems.length
      ? [
          {
            id: 'Column Cells',
            text: 'Column Cells',
            children: columnCellsMenuItems,
          },
        ]
      : []),
  ];

  return (
    <TableHeaderRow.Content {...props.contentProps}>
      <div
        className='header-content'
        onMouseOver={() => setMouseOver(true)}
        onMouseOut={() => setMouseOver(false)}
      >
        {props.children}
        <IconButton
          style={mouseOver ? {} : { opacity: 0 }}
          ref={setPrecisionControlAnchorEl}
          component='a'
          onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
            setAnchorEl(e.currentTarget)
          }
        >
          <MoreVert className='more-vert' fontSize='small' />
        </IconButton>
        <CascadingMenuRoot anchorEl={anchorEl} setAnchorEl={setAnchorEl} items={menuItems} />
        <PrecisionControl
          open={precisionControlAnchorEl !== null && props.precisionControlOpen}
          onClose={() => props.setPrecisionControlOpen(false)}
          PopoverProps={{
            anchorEl: precisionControlAnchorEl,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            transformOrigin: { vertical: 'bottom', horizontal: 'center' },
            transitionDuration: 0,
          }}
          precision={column.precisionOffset ?? 0}
          setPrecision={precision =>
            props.updatePrecision({
              charId: column.charId,
              modifier: column.modifier,
              precisionOffset: precision,
            })
          }
        />
      </div>
    </TableHeaderRow.Content>
  );
};

export default ReactiveDataGridHeader;
