import { TableBandHeader } from '@devexpress/dx-react-grid-material-ui';
import CellEditor from '../components/CellEditor';
import { NodeType, RowField } from './constants';
import {
  DxGridData,
  DxTableRow,
  Node,
  ReportRawData,
  SortableColumn,
  TextAlign,
} from './dataTypes';
import { mapData } from './utils';

/////////////////
//// TABULAR ////
/////////////////

// used by DevExtreme table impl
function convertToDxDataGrid(data: ReportRawData, unformatted?: boolean): DxGridData {
  const columnHashes = data.data.children[0].payload.map(value => value[RowField.COL_HASH]);

  const headers = extractLeafColHeaders(data.headers).map<SortableColumn>((header, index) => {
    const char = data.chars?.find(
      c => c.charId === header.props?.columnKeyValue && c.modifier === header.props?.modifier,
    );

    return {
      name: `${columnHashes[index]}`,
      title: header.name,
      getCellValue: (row: any) => row[columnHashes[index]],

      type: header.props?.dataType,
      charId: header.props?.columnKeyValue,
      modifier: header.props?.modifier,
      precisionOffset: char?.precisionOffset,
      textAlign: getTextAlign(header.props.justify, header.props.dataType),
    };
  });

  // to find the grouping column, we first look for a column called Grouping, if not found, we assume first column as being the grouping column
  const groupingHeader: { name: string } = headers.find(h => h.name === 'Grouping') || headers[0];

  const val = unformatted ? RowField.VAL : RowField.FORMATTED_VAL;

  const rows = mapData(data.data, 0).map(row =>
    row.payload.reduce<DxTableRow>(
      (acc: DxTableRow, cell: any, index: number) => {
        acc[`${columnHashes[index]}`] =
          headers[index].title === groupingHeader.name ? cell[val].trim() : cell[val];

        acc.styles[`${columnHashes[index]}`] = {
          backgroundColor: cell[RowField.BG_COLOR] ? `rgba(${cell[RowField.BG_COLOR]})` : undefined,
          color: cell[RowField.FG_COLOR] ? `rgba(${cell[RowField.FG_COLOR]})` : undefined,
        };

        acc.rowHashCode = cell[RowField.ROW_HASH];
        acc.colHashCodes[`${columnHashes[index]}`] = cell[RowField.COL_HASH];
        acc.editable[`${columnHashes[index]}`] = cell[RowField.IS_EDITABLE];

        return acc;
      },
      {
        parentId: row.parentId,
        id: row.id,
        styles: [],
        rowHashCode: null,
        colHashCodes: [],
        editable: [],
      },
    ),
  );

  const colBands: TableBandHeader.ColumnBands[] = extractColHeaders(data.headers, columnHashes);

  return {
    headers,
    rows,
    colBands,
    groupingHeader,
    expandedRowIds: data.data.props?.expandedRowIds,
  };
}

export const getTextAlign = (justify: string | null | undefined, dataType: number): TextAlign => {
  if (justify === 'Left') return 'left';
  else if (justify === 'Right') return 'right';
  else if (justify === 'Center') return 'center';
  else if ([2, 3, 4].includes(dataType)) /* number or date */ return 'right';
  else if (dataType === 1) /* string */ return 'left';
  else return 'center';
};

let index: number;

const extractColHeaders = (
  rootHeader: Node<any>,
  columnHashes: Number[],
): TableBandHeader.ColumnBands[] => {
  // we make the presumption that the Node passed in is the ROOT node
  index = 0;
  return rootHeader.children?.map(child => buildColumnBand(child, columnHashes)) || [];
};

const buildColumnBand = (
  header: Node<any>,
  columnHashes: Number[],
): TableBandHeader.ColumnBands => {
  const headerBand: TableBandHeader.ColumnBands = { title: header.name };
  if (header.children) {
    headerBand.children = header.children.map(child => buildColumnBand(child, columnHashes));
  } else {
    headerBand.columnName = `${columnHashes[index++]}`;
  }
  return headerBand;
};

// used by data grid tables
// see json-server/data-examples/tabular.json for an output example
function convertToDataGrid(data: ReportRawData): { headers: any[]; rows: any[] } {
  const headers = extractLeafColHeaders(data.headers).map(h => {
    return {
      key: h.name,
      name: h.name,
      editable: h.props.editable,
      resizable: true,
      dataType: h.props.dataType,
      containsDiff: h.props && h.props.diffIndicatorColumn === true,
      columnKeyValue: h.props.columnKeyValue,
      headerRenderer,
      // when using custom editor as we are, editable key is ignored
      // and any header that renders an editor will be editable
      editor: h.props.editable ? CellEditor : null,
    };
  });

  const rows = data.data.children
    .flatMap(n => extractRows(n))
    .map(row => ({ data: row.payload, props: row.props }));

  return { headers, rows };
}

// this method returns just the leaf headers
export function extractLeafColHeaders(node: Node<unknown>): Node<unknown>[] {
  if (node.children && node.children.length > 0) {
    return node.children.flatMap(n => extractLeafColHeaders(n));
  } else {
    return [node];
  }
}

export const extractLeaves = <T extends any = unknown>(node: Node<T> | null): Node<T>[] =>
  !node ? [] : node.children?.length ? node.children.flatMap(extractLeaves) : [node];

function extractRows(node: Node<any>): Node<any>[] {
  if (
    node.children &&
    node.children.length > 0 &&
    node.payload[0][RowField.IS_EXPANDED] // isExpanded
  ) {
    return [node, ...node.children.flatMap(n => extractRows(n))];
  } else {
    return [node];
  }
}

function headerRenderer(props: any) {
  const column = props.column;
  // const textAlign = column.containsDiff
  //   ? 'center'
  //   : columnAlignment(column.dataType);
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <div style={{ width: '100%', textAlign: 'center' }}>{column.name.replace('|', '\n')}</div>
    </div>
  );
}

///////////////////////////
//// PROPORTIONAL DATA ////
///////////////////////////
// Used by Pie, Line, Bar, Composed, Radial Bar, Stacked Bar, Active Pie, 2Level Pie, Area,
// Brush Bar, Stacked Brush Bar, and Mixed Bar charts
// see json-server/data-examples/proportional.json for output example
const convertToProportionalData = (isPie: boolean) => (
  data: ReportRawData,
): { headers: any[]; rows: any[]; groupingHeader: { name: string; index: number } } => {
  const headers: { name: string; index: number }[] = extractLeafColHeaders(data.headers).map(
    (h, i) => ({
      name: h.name,
      index: i,
    }),
  );
  // to find the grouping column, we first look for a column called Grouping, if not found, we assume first column as being the grouping column
  const groupingHeader: { name: string; index: number } =
    headers.find(h => h.name === 'Grouping') || headers[0];

  // header names give us the items (eg. number of pies or number of lines in a report)
  // by default, we expect those to be on X axis for lines, bars and on Y axis for pies
  const headerNames = headers.map(h => h.name).filter(h => h !== groupingHeader.name);

  const proportionalData =
    data.data.children.length === 1 &&
    data.data.children[0].type === NodeType.TOTAL &&
    data.data.children[0].children // report has a total node, look at the data underneath the total node
      ? buildProportionalData(data.data.children[0], headers, groupingHeader, isPie)
      : buildProportionalData(data.data, headers, groupingHeader, isPie);

  return { headers: headerNames, rows: proportionalData, groupingHeader };
};

function buildProportionalData(
  node: Node<any>,
  headers: { name: string; index: number }[],
  groupingHeader: { name: string; index: number },
  isPie: boolean,
) {
  return node.children
    ? node.children.map(n => ({
        [groupingHeader.name]: n.payload[groupingHeader.index][RowField.FORMATTED_VAL],
        ...buildProportionalDataAttributes(n.payload, headers, groupingHeader.index, isPie),
      }))
    : [];
}

function buildProportionalDataAttributes(
  payload: [],
  headers: { name: string; index: number }[],
  groupingHeaderIndex: number,
  isPie: boolean,
) {
  // takes all report columns except grouping and converts them in one object with {[header.name]: val, [header.name]Formatted: formattedVal}
  return payload.reduce((acc, p, i) => {
    if (i !== groupingHeaderIndex) {
      const val = !isPie || isNaN(p[RowField.VAL]) ? p[RowField.VAL] : Math.abs(p[RowField.VAL]);
      acc[headers[i].name] = val;
      acc[`${headers[i].name}Formatted`] = p[RowField.FORMATTED_VAL];
      acc[`${headers[i].name}rowcol`] = `${p[RowField.ROW_HASH]}x${p[RowField.COL_HASH]}`;
    }
    return acc;
  }, {});
}

///////////////////////
//// TREE MAP DATA ////
///////////////////////
// Used by Tree Map and Geo Map
// see json-server/data-examples/tree-map.json for output example
function convertToTreeMapData(data: ReportRawData): { headers: any[]; rows: any[] } {
  const headers: { name: string; index: number }[] = extractLeafColHeaders(data.headers).map(
    (h, i) => ({
      name: h.name,
      index: i,
    }),
  );

  if (headers.length < 2) {
    return { headers, rows: [] };
  }

  if (
    data.data.children &&
    data.data.children.length > 0 &&
    data.data.children[0].type === NodeType.TOTAL
  ) {
    // this is a report that has a total ... walk total's children
    return {
      headers: headers.map(h => h.name),
      rows: recurseTreeMapData(headers, data.data.children[0].children),
    };
  } else {
    // this report doesn't have a total, walk root's children
    return {
      headers: headers.map(h => h.name),
      rows: recurseTreeMapData(headers, data.data.children),
    };
  }
}

function recurseTreeMapData(headers: { name: string; index: number }[], nodes: Node<any>[]): any[] {
  return nodes.map(n => {
    const el: any = {};
    const row = n.payload;
    el.name = row[0][RowField.FORMATTED_VAL]; // 2 used to be val but i think formatted val is more appropriate
    el.row = row[0][RowField.ROW_HASH]; // 0
    el.col = row[0][RowField.COL_HASH]; // 1
    el.value = row[1][RowField.VAL]; // 2
    el.formattedValue = row[1][RowField.FORMATTED_VAL];

    let childSize = el.value;
    if (childSize && !isNaN(childSize)) {
      childSize = Math.abs(childSize);
    }
    el.size = childSize;

    const rowcol = `${row[1][RowField.ROW_HASH]}x${row[1][RowField.COL_HASH]}`;
    el.rowcol = rowcol;

    if (row.length > 2) {
      // for geo visualizations we expect two extra columns: currency and secondary formatted value (eg. % MtM)
      el.valueCurrency = row[2][RowField.FORMATTED_VAL];
      el.secondaryFormattedValue = row[3][RowField.FORMATTED_VAL];
    }

    if (row.length > 4) {
      // in the case of geo visualizations, these are treated as additional key/value attributes to show in a third section
      const additionalAttributes: { name: string; formattedValue: any }[] = [];
      for (let k = 4; k < row.length; k++) {
        additionalAttributes.push({
          name: headers.find(h => h.index === k).name,
          formattedValue: row[k][RowField.FORMATTED_VAL],
        });
      }
      el.additionalAttributes = additionalAttributes;
    }

    if (n.children && n.children.length > 0) {
      el.children = recurseTreeMapData(headers, n.children);
    }

    return el;
  });
}

///////////////////
//// PLOT DATA ////
///////////////////
// Used by Scatter and Bubble charts
// see json-server/data-examples/plot.json for output example
function convertToPlotDataSeries(
  data: ReportRawData,
): { headers: any[]; rows: any[]; groupingHeader: { name: string; index: number } } {
  const headers: { name: string; index: number }[] = extractLeafColHeaders(data.headers).map(
    (h, i) => ({
      name: h.name,
      index: i,
    }),
  );
  // to find the grouping column, we first look for a column called Grouping, if not found, we assume first column as being the grouping column
  const groupingHeader: { name: string; index: number } =
    headers.find(h => h.name === 'Grouping') || headers[0];
  const headerNames = headers.map(h => h.name).filter(h => h !== groupingHeader.name);

  let dataSeries;

  if (
    data.data.children &&
    data.data.children.length > 0 &&
    data.data.children[0].type === NodeType.TOTAL
  ) {
    // this is a report that has a total ... walk total's children
    dataSeries = generatePlotDataSeries(data.data.children[0].children, headers, groupingHeader);
  } else {
    // this report doesn't have a total, walk root's children
    dataSeries = generatePlotDataSeries(data.data.children, headers, groupingHeader);
  }

  return { headers: headerNames, rows: dataSeries, groupingHeader };
}

function generatePlotDataSeries(
  nodes: Node<any>[],
  headers: { name: string; index: number }[],
  groupingHeader: { name: string; index: number },
): any[] {
  const plotDataSeries: any[] = [];
  if (nodes && nodes.length > 0) {
    if (nodes[0].children && nodes[0].children.length > 0) {
      // this is a dual grouping report
      // we treat first grouping buckets as multiple series of scatter data
      return nodes.map(c =>
        generateSinglePlotDataSeries(c.payload[0][RowField.FORMATTED_VAL], c.children, headers),
      );
    } else {
      // this is a single grouping report
      // we treat this as a single data series
      return [generateSinglePlotDataSeries(groupingHeader.name, nodes, headers)];
    }
  }
  return plotDataSeries;
}

function generateSinglePlotDataSeries(
  seriesName: string,
  nodes: Node<any>[],
  headers: { name: string; index: number }[],
): any {
  return {
    name: seriesName,
    data: nodes.map(n => {
      const row: any = {};
      headers.forEach(h => {
        row[`${h.name}`] = n.payload[h.index][RowField.VAL];
        row[`${h.name}Formatted`] = n.payload[h.index][RowField.FORMATTED_VAL];
        row.rowcol = `${n.payload[h.index][RowField.ROW_HASH]}x${
          n.payload[h.index][RowField.COL_HASH]
        }`;
      });
      return row;
    }),
  };
}

//////////////////////////////
//// LEGACY XY CHART DATA ////
//////////////////////////////
// Used by x/y data series (legacy)
const convertToXYDataSeries = (generator: any) => (
  data: ReportRawData,
): { headers: any[]; rows: any[]; groupingHeader: { index: number; name: string } } => {
  const headers: { name: string; index: number }[] = extractLeafColHeaders(data.headers).map(
    (h, i) => ({
      name: h.name,
      index: i,
    }),
  );
  // to find the grouping column, we first look for a column called Grouping, if not found, we assume first column as being the grouping column
  const groupingHeader: { name: string; index: number } =
    headers.find(h => h.name === 'Grouping') || headers[0];
  const headerNames = headers.map(h => h.name).filter(h => h !== groupingHeader.name);

  let dataSeries;

  if (
    data.data.children &&
    data.data.children.length > 0 &&
    data.data.children[0].type === NodeType.TOTAL
  ) {
    // this is a report that has a total ... walk total's children
    dataSeries = generatePlotDataSeries(data.data.children[0].children, headers, groupingHeader);
  } else {
    // this report doesn't have a total, walk root's children
    dataSeries = generator(data.data.children, headers);
  }

  return { headers: headerNames, rows: dataSeries, groupingHeader };
};

function generateXYStackedDataSeries(
  nodes: Node<any>[],
  headers: { name: string; index: number }[],
): any[] {
  if (nodes && nodes.length > 0) {
    return nodes.map(n => {
      const dataEntry = {};
      headers.forEach((header, index) => (dataEntry[header.name] = n.payload[index][RowField.VAL]));
      return dataEntry;
    });
  } else {
    return [];
  }
}

function generateXYPlotDataSeries(
  nodes: Node<any>[],
  headers: { name: string; index: number }[],
): any[] {
  const plotDataSeries: any[] = [];
  if (nodes && nodes.length > 0) {
    const numXYPairs = headers.length - 2;

    for (let i = 0; i < numXYPairs; i++) {
      plotDataSeries[i] = generateMultiPlotDataSeries(headers[i + 2].name, nodes, headers, i);
    }
  }
  return plotDataSeries;
}

function generateMultiPlotDataSeries(
  seriesName: string,
  nodes: Node<any>[],
  headers: { name: string; index: number }[],
  pairNum: any,
): any {
  return {
    name: seriesName,
    data: nodes.map(n => {
      const row: any = {};

      row[headers[0].name] = n.payload[headers[0].index][RowField.VAL];
      row[headers[0].name + 'Formatted'] = n.payload[headers[0].index][RowField.FORMATTED_VAL];
      row[headers[1].name] = n.payload[headers[1].index][RowField.VAL];
      row[headers[1].name + 'Formatted'] = n.payload[headers[1].index][RowField.FORMATTED_VAL];
      row[headers[2 + pairNum].name] = n.payload[headers[2 + pairNum].index][RowField.VAL];
      row[headers[2 + pairNum].name + 'Formatted'] =
        n.payload[headers[2 + pairNum].index][RowField.FORMATTED_VAL];
      return row;
    }),
  };
}

/////////////////////
//// BULLET DATA ////
/////////////////////
// Used by Bullet graph
// see json-server/data-examples/bullet.json for output example
function convertToBulletData(data: ReportRawData): { headers: any[]; rows: any[] } {
  const headers: string[] = extractLeafColHeaders(data.headers).map(h => h.name);

  const bulletValues: any = { scaleMin: 0 };

  const dataRow = data.data.children[0].payload;

  headers.forEach((element, index) => {
    if (element.toLowerCase().includes('low')) {
      bulletValues.badVal = dataRow[index][RowField.VAL] * 100;
    } else if (element.toLowerCase().includes('medium')) {
      bulletValues.satisfactoryVal = dataRow[index][RowField.VAL] * 100;
    } else if (element.toLowerCase().includes('high')) {
      bulletValues.scaleMax = dataRow[index][RowField.VAL] * 100;
    } else if (element.toLowerCase().includes('bench')) {
      bulletValues.symbolMarker = Math.abs(dataRow[index][RowField.VAL] * 100);
      bulletValues.bench = element;
    } else {
      bulletValues.performanceVal = Math.abs(dataRow[index][RowField.VAL] * 100);
      bulletValues.metric = element;
    }
  });

  return { headers, rows: [bulletValues] };
}

export const DataConverters = {
  TABLE: convertToDataGrid,
  DX_TABLE: convertToDxDataGrid,
  // Option 1: Proportional Chart
  // pies (true -> apply abs on values)
  ACTIVE_PIE_CHART: convertToProportionalData(true),
  PIE_CHART: convertToProportionalData(true),
  TWO_LEVEL_PIE_CHART: convertToProportionalData(true),
  RADIAL_BAR_CHART: convertToProportionalData(false),
  // other proportional
  BAR_CHART: convertToProportionalData(false),
  STACKED_BAR_CHART: convertToProportionalData(false),
  LINE_GRAPH: convertToProportionalData(false),
  COMPOSED_CHART: convertToProportionalData(false),
  AREA_CHART: convertToProportionalData(false),
  BRUSH_BAR_CHART: convertToProportionalData(false),
  STACKED_BRUSH_BAR_CHART: convertToProportionalData(false),
  MIXED_BAR_CHART: convertToProportionalData(false),

  // Option 2: Tree map data
  TREE_MAP: convertToTreeMapData,
  MAP_CHART: convertToTreeMapData,

  // Option 3: plot data series
  SCATTER_CHART: convertToPlotDataSeries, // 2-axis (x and y)
  BUBBLE_CHART: convertToPlotDataSeries, // 3-axis (x and y + bubble size)

  // Option 4: bullet
  BULLET_GRAPH: convertToBulletData,

  // x/y data series (legacy)
  XY_LINE_CHART: convertToXYDataSeries(generateXYPlotDataSeries),
  XY_SPLINE_LINE_CHART: convertToXYDataSeries(generateXYPlotDataSeries),
  XY_SPLINE_LINE_ONLY_CHART: convertToXYDataSeries(generateXYPlotDataSeries),
  XY_LINE_NO_SYMBOL_CHART: convertToXYDataSeries(generateXYPlotDataSeries),
  XY_SYMBOL_NO_LINE_CHART: convertToXYDataSeries(generateXYPlotDataSeries),
  XY_STACKED_BAR_CHART: convertToXYDataSeries(generateXYStackedDataSeries),
  XY_BAR_CHART: convertToXYDataSeries(generateXYStackedDataSeries),
};
