import { useReducer } from 'react';

/**
 * Forces a re-render.
 * (cf. https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate)
 */
const useForceRender = () => {
  const forceRender = useReducer(x => x + 1, 0)[1];

  return forceRender;
};

export default useForceRender;
