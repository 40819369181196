import { Column } from '@devexpress/dx-react-grid';
import {
  TableBandHeader,
  TableTreeColumn,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { ArrowRight } from '@material-ui/icons';
import { CSSProperties, FC } from 'react';
import {
  CellPropsWithStyle,
  DataCellPropsWithStyle,
  DxTableRow,
  OnElementClick,
  OnElementClickEvent,
  PendingRequest,
  TextAlign,
} from '../shared/dataTypes';

interface Props {
  dataCellProps: DataCellPropsWithStyle;
  expandable?: boolean;
  sparkLinesItems: Array<Array<{ uv: number }>>;
  onClick?: OnElementClick;
  getColumnIdByName: (name: string) => number;
  pendingRequests: { [requestId: string]: PendingRequest };
  sequenceId: number;
  showGrandTotalRow: boolean;
  cellIsAwaitingEditResponse?: (rowIndex: number, colIndex: number) => boolean;
  selectedElements: string[];
}

const ReactiveDataGridCellComp: FC<Props> = props => {
  // DevExpress should use generics to allow us to define the type of
  // props.dataCellProps.row, but they don't, so we have to do it this way.
  const row: DxTableRow = props.dataCellProps.row;
  const cellId = `${row.rowHashCode}x${row.colHashCodes[props.dataCellProps.column.name]}`;

  const rowSelected = props.selectedElements?.some(element =>
    element?.startsWith(`${row.rowHashCode}x`),
  );
  const cellSelected = props.selectedElements?.includes(cellId);

  const onClick = (e: OnElementClickEvent) =>
    props.onClick?.(cellId)({ ctrlKey: e.ctrlKey, metaKey: e.metaKey });

  // DevExpress typings don't know that we've secretly added textAlign to this object
  const { textAlign } = props.dataCellProps.column as Column & { textAlign: TextAlign };

  const style: CSSProperties = {
    ...row.styles[props.dataCellProps.column.name],
    ...props.dataCellProps.style,
    cursor: 'default',
    textAlign,
  };

  let selectionColor = '#dfe8f0';
  let defaultColor = style.backgroundColor || '#ffffff';

  if (!style.backgroundColor && props.showGrandTotalRow && props.dataCellProps.tableRow.rowId === 1)
    [selectionColor, defaultColor] = [defaultColor, selectionColor];

  if (cellSelected) {
    style.backgroundColor = selectionColor;
    style.color = '#000000';
    style.border = '1px #3077c2 double';
  } else if (rowSelected) {
    style.backgroundColor = selectionColor;
  } else style.backgroundColor = defaultColor;

  // Border + padding = 3px whether the cell is editable or not.
  if (row.editable[props.dataCellProps.column.name]) {
    style.border = '1px solid #a6c8ff';
    style.paddingTop = style.paddingBottom = '2px';
  } else style.paddingTop = style.paddingBottom = '3px';

  // Right-justify numeric values
  if (
    typeof props.dataCellProps.value === 'number' ||
    (typeof props.dataCellProps.value === 'string' &&
      !isNaN(Number(props.dataCellProps.value.replace(',', ''))))
  )
    style.textAlign = 'right';

  if (
    props.dataCellProps.column.title === 'Market Value Trend' ||
    props.dataCellProps.column.title === '6 month trend' ||
    props.dataCellProps.column.title === 'SA Trend'
  ) {
    return null;
    /*
    Commenting out temporarily - 5/3/2021
    return (
      <td style={{ verticalAlign: 'bottom' }}>
        <ResponsiveContainer minWidth='100%' height={25}>
          <AreaChart
            data={props.sparkLinesItems[row.id]}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <Area type='monotone' dataKey='uv' stroke='#8884d8' fill='#8884d8' />
          </AreaChart>
        </ResponsiveContainer>
      </td>
    );
*/
  } else {
    let dataCellProps: DataCellPropsWithStyle;
    if (row.editable[props.dataCellProps.column.name]) dataCellProps = props.dataCellProps;
    else {
      const { onDoubleClick, ...rest } = props.dataCellProps;
      dataCellProps = rest;
    }

    // Do not include children in TableTreeColumn.Cell unless you want to break all
    // expandability functionality. Instead, custom content needs to be included
    // via the contentComponent prop of TableTreeColumn.
    if (props.expandable)
      return (
        <TableTreeColumn.Cell
          {...dataCellProps}
          style={style}
          onClick={onClick}
          onContextMenu={onClick}
        />
      );
    // For VirtualTable.Cell, it works differently - there is no contentComponent of
    // VirtualTable, so we include the content as children of VirtualTable.Cell.
    else {
      const pendingEdit = props.cellIsAwaitingEditResponse?.(
        props.dataCellProps.row.rowHashCode,
        props.getColumnIdByName(props.dataCellProps.column.name),
      );

      return (
        <VirtualTable.Cell
          {...dataCellProps}
          style={{ ...style, position: pendingEdit ? 'relative' : 'static' }}
          onClick={onClick}
          onContextMenu={onClick}
        >
          {pendingEdit && (
            <ArrowRight
              style={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                transform: 'rotate(-45deg)',
                fill: '#da1e28',
              }}
            />
          )}

          {props.dataCellProps.value}
        </VirtualTable.Cell>
      );
    }
  }
};

const ReactiveDataGridExpandButton: FC<TableTreeColumn.ExpandButtonProps> = props => (
  // 18px here, plus the border and padding above (2 x 3px) = 24px total row height
  <TableTreeColumn.ExpandButton {...props} style={{ height: '18px', color: 'inherit' }} />
);

const ReactiveDataGridBandHeaderCell: FC<CellPropsWithStyle> = props => (
  <TableBandHeader.Cell {...props} style={{ textAlign: 'center' }} />
);

const ReactiveDataGridCell = ReactiveDataGridCellComp;

export { ReactiveDataGridCell, ReactiveDataGridBandHeaderCell, ReactiveDataGridExpandButton };
