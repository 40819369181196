import { FC } from 'react';
import RoundedButton from '../../shared/RoundedButton';

interface Props {
  onApply: () => void;
  onCancel: () => void;
  disableApply?: boolean;
}

const DatePickerButtons: FC<Props> = props => (
  <div className='buttons'>
    <RoundedButton size='small' variant='outlined' onClick={props.onCancel}>
      Cancel
    </RoundedButton>
    <RoundedButton
      disabled={props.disableApply}
      size='small'
      variant='contained'
      style={{
        color: '#fff',
        backgroundColor: props.disableApply ? 'rgba(0, 0, 0, 0.12)' : '#00677f',
      }}
      onClick={props.onApply}
    >
      Apply
    </RoundedButton>
  </div>
);

export default DatePickerButtons;
