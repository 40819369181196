import { ReactElement } from 'react';
import Cell from './Cell';
import { Column, Datum } from './model';

export interface Props<D extends Datum, V = unknown> {
  tableId: string;
  columns: Column<D, V>[];
  datum: D;
  onClick?: () => void;
}

const Row = <D extends Datum, V = unknown>({
  tableId,
  columns,
  datum,
  onClick,
}: Props<D, V>): ReactElement | null => (
  <tr className={`arc-table-row arc-table-${tableId}-row`} {...{ onClick }}>
    {columns.map(column => (
      <Cell {...{ tableId, column, datum }} key={`cell-${column.id}-${datum.id}`} />
    ))}
  </tr>
);

export default Row;
