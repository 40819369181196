import { FC } from 'react';
import { LIGHT_BORDER } from '../../shared/constants';
import RoundedButton from '../../shared/RoundedButton';
import { DropdownActionButton, DropdownActionButtonMenuItemProps } from '../DropdownActionButton';

interface Props {
  applyButtonText: string;
  onApplyClick: () => void;
  overflowApplyButtonProps?: DropdownActionButtonMenuItemProps[];
  onCancelClick: () => void;
  applyDisabled?: boolean;
  cancelDisabled?: boolean;
}

const DrawerFooter: FC<Props> = props => (
  <div
    style={{
      height: '48px',
      minHeight: '48px',
      borderTop: LIGHT_BORDER,
      backgroundColor: '#fff',
      zIndex: 2, // cover up Accordion while it's animated
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    }}
  >
    <div style={{ marginRight: '1em' }}>{props.children}</div>
    <RoundedButton
      onClick={props.onCancelClick}
      variant='outlined'
      color='primary'
      disabled={props.cancelDisabled}
    >
      Cancel
    </RoundedButton>

    {props.overflowApplyButtonProps?.length ? (
      <DropdownActionButton
        buttonComponent={
          <RoundedButton
            style={{
              margin: 0,
              minWidth: 56,
              paddingRight: 12,
            }}
            disableElevation
            variant='contained'
            color='primary'
            onClick={props.onApplyClick}
            disabled={props.applyDisabled}
          >
            {props.applyButtonText}
          </RoundedButton>
        }
        menuButtonProps={props.overflowApplyButtonProps}
        disabled={props.applyDisabled}
      />
    ) : (
      <RoundedButton
        variant='contained'
        color='primary'
        onClick={props.onApplyClick}
        disabled={props.applyDisabled}
      >
        {props.applyButtonText}
      </RoundedButton>
    )}
  </div>
);

export default DrawerFooter;
