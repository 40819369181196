import { Checkbox } from '@material-ui/core';
import { ReactElement } from 'react';
import { Column, Datum } from './model';

export interface Props<D extends Datum, V = unknown> {
  tableId: string;
  column: Column<D, V>;
  datum: D;
}

const Cell = <D extends Datum, V = unknown>({
  tableId,
  column,
  datum,
}: Props<D, V>): ReactElement | null => {
  const getValue = column.getValue ?? ((datum: D) => datum[column.id] as V);
  const getLabel = column.getLabel ?? ((datum: D) => `${getValue(datum)}`);

  return (
    <td className={`arc-table-cell arc-table-${tableId}-cell`}>
      {column.onCheckboxChange ? (
        <Checkbox
          color='primary'
          checked={getLabel(datum) === 'true'}
          onChange={e => column.onCheckboxChange(datum, e.target.checked)}
        />
      ) : (
        <p>{getLabel(datum)}</p>
      )}
    </td>
  );
};

export default Cell;
