import moment, { Moment } from 'moment';
import { FC } from 'react';
import { CalendarDay, DayPickerSingleDateController } from 'react-dates';
import DatePickerDay from './DatePickerDay';
import DatePickerMonth from './DatePickerMonth';

type BaseProps = { runExists: (m: Moment) => boolean; date: string; initDate?: string }; // YYYY-MM-DD
type EnabledProps = BaseProps & { disabled?: false; onDateChange: (date: string) => void };
type DisabledProps = BaseProps & { disabled: true; onDateChange?: never };

const SingleDatePicker: FC<EnabledProps | DisabledProps> = props => (
  <DayPickerSingleDateController
    isOutsideRange={props.disabled ? () => true : undefined}
    date={props.date?.length === 10 ? moment(props.date) : undefined}
    onDateChange={e => props.onDateChange?.(e.format('YYYY-MM-DD'))}
    focused
    onFocusChange={() => {}}
    initialVisibleMonth={() => moment(props.date?.length === 10 ? props.date : props.initDate)}
    renderDayContents={m => <DatePickerDay moment={m} runExists={props.runExists(m)} />}
    renderCalendarDay={p => <CalendarDay {...p} daySize={24} />}
    hideKeyboardShortcutsPanel
    renderMonthElement={p => (
      <DatePickerMonth
        {...p}
        currentDate={props.date?.[0]?.length === 10 ? props.date?.[0] : props.initDate}
      />
    )}
  />
);

export default SingleDatePicker;
