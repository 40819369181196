import { createTheme, ThemeOptions } from '@material-ui/core';

export const themeOptions: ThemeOptions = {
  palette: {
    primary: { main: '#1c7293', dark: '#225164', light: 'rgba(0,103,127,0.10)' },
    secondary: { main: '#bd374a' },
  },
  typography: {
    fontFamily: ['Source Sans Pro', 'Roboto', 'Arial', 'sans-serif'].join(','),
    h1: { fontWeight: 600, fontSize: '16px' },
    h2: { fontWeight: 600, fontSize: '14px' },
    h3: { fontWeight: 600, fontSize: '12px' },
    body1: { fontSize: '14px' },
    body2: { fontSize: '12px' },
    button: {
      textTransform: 'none',
    },
  },
  shape: { borderRadius: 0 },
  overrides: { MuiFormControlLabel: { label: { fontSize: '12px' } } },
};

export const theme = createTheme(themeOptions);
