import ArrowDownwardIcon from '@material-ui/icons/ArrowDownwardOutlined';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpwardOutlined';
import { FC } from 'react';
import { SortDirection } from '../../shared/dataTypes';

export interface Props {
  direction: SortDirection;
}

const getIcon = (direction: SortDirection) => {
  switch (direction) {
    case 'asc':
      return ArrowUpwardIcon;

    case 'desc':
      return ArrowDownwardIcon;
  }
};

const SortIndicator: FC<Props> = ({ direction }) => {
  const Icon = getIcon(direction);

  return <Icon className='sort-indicator' />;
};

export default SortIndicator;
