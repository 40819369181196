import { IconButton } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';
import { FC } from 'react';

export interface Props {
  onClick: () => void;
  disabled?: boolean;
  icon: SvgIconComponent;
}

const PrecisionControlButton: FC<Props> = props => (
  <IconButton
    className='precision-control-button'
    color='primary'
    onClick={props.onClick}
    disabled={props.disabled}
  >
    <props.icon className='precision-control-button-icon' />
  </IconButton>
);

export default PrecisionControlButton;
