import { FC } from 'react';
import { ReportRawData, Sort } from '../../shared/dataTypes';
import Table, { Props as TableProps } from '../Table/Table';
import { ReportDatum, SimpleReportData } from './model';
import { transformReport, transformSort } from './transformers';

export interface Props extends Omit<TableProps<ReportDatum>, 'columns' | 'data'> {
  reportProps?: Pick<ReportRawData, 'headers' | 'chars' | 'data'>;
  simpleReportProps?: SimpleReportData;
  onSortColumn?: (columnId: number) => void;
  currentSort?: Sort;
  charProp?: string;
  searchChars?: string[];
}

const ReportTable: FC<Props> = ({ reportProps, currentSort, simpleReportProps, ...rest }) =>
  !reportProps && !simpleReportProps ? null : (
    <Table
      {...{
        ...(simpleReportProps || transformReport(reportProps)),
        ...(currentSort ? { sortOrder: transformSort(currentSort) } : {}),
        ...rest,
      }}
    />
  );

export default ReportTable;
