import { Drawer, DrawerProps } from '@material-ui/core';
import { FC, useRef, useState } from 'react';
import './resizable-drawer.scss';

interface Props {
  drawerProps: DrawerProps;
  defaultWidth: number;
  onResizeDone?: (width: number) => void;
  minimumWidth?: number;
}

const ResizableDrawer: FC<Props> = props => {
  const [width, _setWidth] = useState(props.defaultWidth);
  // event listeners can't access current width state, so we need to use a ref to get current state
  const widthRef = useRef<number>(null);
  const setWidth = (width: number) => {
    widthRef.current = width;
    _setWidth(width);
  };
  const anchor = props.drawerProps.anchor;

  const handleMouseMove = (e: MouseEvent) => {
    const newWidth =
      anchor === 'right'
        ? document.body.offsetLeft + document.body.offsetWidth - e.clientX
        : e.clientX;
    if (newWidth >= (props.minimumWidth || 368)) {
      setWidth(newWidth);
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
    props.onResizeDone?.(widthRef.current);
  };

  return (
    <Drawer {...(props.drawerProps ?? {})} PaperProps={{ style: { width: `${width}px` } }}>
      <div
        onMouseDown={() => {
          document.addEventListener('mouseup', handleMouseUp, true);
          document.addEventListener('mousemove', handleMouseMove, true);
        }}
        className={`resizable-drawer-dragger ${
          anchor === 'left' ? 'resizable-left' : 'resizable-right'
        }`}
      />
      {props.children}
    </Drawer>
  );
};

export default ResizableDrawer;
