import { Popover, TextField } from '@material-ui/core';
import { FC } from 'react';
import './report-dates.scss';

interface Props {
  anchorEl: HTMLDivElement | null;
  setAnchorEl: (anchorEl: HTMLDivElement | null) => void;
  onClick?: () => void;
  textFieldValue: string;
  disabled?: boolean;
  variant?: 'standard' | 'outlined';
  size?: 'small' | 'medium';
  hideLabel?: boolean;
}

const ReportDatesPopover: FC<Props> = props => (
  <div className='report-dates-textfield'>
    <TextField
      label={props.hideLabel ? '' : 'Reporting Date'}
      value={props.textFieldValue || ''}
      InputProps={{ readOnly: true }}
      onClick={event => {
        props.setAnchorEl(event.currentTarget);
        props.onClick?.();
      }}
      style={{ width: '100%' }}
      disabled={props.disabled}
      variant={props.variant}
      size={props.size}
    />
    <Popover
      open={!!props.anchorEl && !props.disabled}
      onClose={() => props.setAnchorEl(null)}
      anchorEl={props.anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      className='report-dates-control'
    >
      {props.children}
    </Popover>
  </div>
);

export default ReportDatesPopover;
