import {
  CircularProgress,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { FC, useState } from 'react';
import Tree from './Tree';
import './tree-dropdown.scss';

const DEFAULT_HEIGHT = 725;
const DEFAULT_WIDTH = 325;

export interface TreeDropdownItem<T = string | number> {
  id: T;
  name: string;
  parentId?: T;
  selected?: boolean;
  disabled?: boolean;
  indentLevel?: number;
  children?: TreeDropdownItem[];
}

interface Props {
  dataSource?: TreeDropdownItem[];
  onItemSelectionChanged: (ids: (string | number)[]) => void;
  textFieldProps?: TextFieldProps;
  height?: number;
  width?: number;
}

const TreeDropdown: FC<Props> = props => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <TextField
        className='tree-dropdown-textfield'
        fullWidth
        value={
          props.dataSource
            ?.filter(d => d.selected)
            .filter((_d, i) => i < 10)
            .map(d => d.name)
            .join(', ') || ''
        }
        size='small'
        variant='outlined'
        placeholder='Select a value...'
        {...props.textFieldProps}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={e => setAnchorEl(anchorEl ? null : e.currentTarget)}>
                <KeyboardArrowDown />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popover
        {...{ anchorEl }}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        className='tree-dropdown-popover'
      >
        {props.dataSource?.length > 0 ? (
          <Tree
            dataSource={props.dataSource}
            onItemSelectionChanged={props.onItemSelectionChanged}
            height={props.height || DEFAULT_HEIGHT}
            width={props.width || DEFAULT_WIDTH}
          />
        ) : (
          <div
            className='loading'
            style={{
              height: `${props.height || DEFAULT_HEIGHT}px`,
              width: `${props.width || DEFAULT_WIDTH}px`,
            }}
          >
            <CircularProgress />
          </div>
        )}
      </Popover>
    </>
  );
};

export default TreeDropdown;
