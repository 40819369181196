import { DateContextItem } from './model';

// Choose the run id from the date contexts as follows: If the first run id is blank, then
// use it because it represents the base date. Otherwise use the id of the last context.
const getDefaultDateContextRun = (contexts: DateContextItem[]) =>
  !contexts || contexts.length === 0 || contexts[0]?.id === ''
    ? ''
    : contexts[contexts.length - 1]?.id;

export default getDefaultDateContextRun;
