import { ReactElement } from 'react';
import { Column, Datum, SortOrder } from './model';
import SortIndicator from './SortIndicator';

export interface Props<D extends Datum, V = unknown> {
  tableId: string;
  columns: Column<D, V>[];
  onSortColumn?: (columnId: string | number) => void;
  sortOrder?: SortOrder;
}

const Header = <D extends Datum, V = unknown>({
  tableId,
  columns,
  onSortColumn,
  sortOrder,
}: Props<D, V>): ReactElement | null => (
  <thead className={`arc-table-header arc-table-${tableId}-header`}>
    <tr>
      {columns.map(({ id, header }) => (
        <th
          key={`header-${id}`}
          className={onSortColumn ? 'sortable' : ''}
          onClick={onSortColumn && (() => onSortColumn(id))}
        >
          <span className='header-content'>
            <span className='label'>{header ?? id}</span>
            {id === sortOrder?.columnId && <SortIndicator direction={sortOrder.direction} />}
          </span>
        </th>
      ))}
    </tr>
  </thead>
);

export default Header;
