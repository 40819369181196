import { FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { FC } from 'react';

interface Props {
  disabled?: boolean;
  items: string[];
  selectedDate: string;
  selectedRun: string;
  setSelectedRun?: (selectedRun: string) => void;
}

const RadioRuns: FC<Props> = props => (
  <div className='runs'>
    <Typography>Available Runs</Typography>
    <RadioGroup
      value={props.selectedRun || ''}
      onChange={e => props.setSelectedRun?.(e.target.value)}
    >
      {props.items.map((d, i) => (
        <FormControlLabel
          disabled={props.disabled}
          key={i}
          value={d}
          label={d || props.selectedDate}
          control={<Radio color='primary' />}
        />
      ))}
    </RadioGroup>
  </div>
);

export default RadioRuns;
