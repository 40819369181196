import { createContext, useContext } from 'react';
import { createDefaultState, TableState } from './model';

export interface TableContextProps {
  state: TableState;
  dispatch: {
    setSearchTerm: (searchTerm: string) => void;
  };
}

export const createDefaultContextProps = (): TableContextProps => ({
  state: createDefaultState(),
  dispatch: {
    setSearchTerm: _searchTerm => {},
  },
});

export const TableStateContext = createContext<TableContextProps>(createDefaultContextProps());
export const useTableState = () => useContext(TableStateContext);
