import { ReactElement } from 'react';
import { processSearchString } from '../../shared/utils';
import Header from './Header';
import { Column, Datum, SortOrder } from './model';
import Row from './Row';
import { useTableState } from './state/context';
import './table.scss';

export interface Props<D extends Datum, V = unknown> {
  id: string;

  columns: Column<D, V>[];
  visibleColumnIds?: (string | number)[];
  onSortColumn?: (columnId: string | number) => void;

  data: D[];
  onDatumClick?: (datum: D) => void;

  // Only for header UI -- does not sort the data.
  sortOrder?: SortOrder;

  charProp?: string;
  searchChars?: string[];
}

// (cf. https://stackoverflow.com/q/62416699)
const Table = <D extends Datum, V = unknown>({
  id,
  columns: allColumns,
  visibleColumnIds,
  onSortColumn,
  data,
  onDatumClick,
  sortOrder,
  charProp,
  searchChars,
}: Props<D, V>): ReactElement | null => {
  const {
    state: { searchTerm },
  } = useTableState();

  const columns = !visibleColumnIds
    ? allColumns
    : visibleColumnIds.flatMap(columnId => {
        const column = allColumns.find(({ id }) => id === columnId);

        return column ? [column] : [];
      });

  return (
    <>
      <table className={`arc-table arc-table-${id} fixed-header`}>
        <Header tableId={id} {...{ columns, onSortColumn, sortOrder }} />

        <tbody>
          {(searchChars && searchTerm
            ? data.filter(datum =>
                processSearchString(
                  Object.entries(datum[charProp])
                    .filter(([key]) => searchChars.includes(key))
                    .map(([_key, value]) => value)
                    .join(),
                ).includes(processSearchString(searchTerm)),
              )
            : data
          ).map(datum => (
            <Row
              key={`row-${datum.id}`}
              tableId={id}
              {...{
                columns,
                datum,
              }}
              onClick={() => onDatumClick?.(datum)}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Table;
