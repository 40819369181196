import { Node, NodeWithNumericParentId } from './dataTypes';

const sequence = { id: 1 };
export const nextSequenceId = (): number => sequence.id++;

const requestSequence = { id: 1 };
export const nextRequestId = (): number => requestSequence.id++;

export const mapData = (data: Node<any>, parentId: number): NodeWithNumericParentId[] => {
  if (!data) {
    return [];
  }
  if (data.id === 'rootFolderId' || data.type === 'ROOT') {
    return data.children.flatMap(child => mapData(child, parentId));
  }

  const node: NodeWithNumericParentId = { ...data, parentId, name: data.name };

  return data.children
    ? [node].concat(data.children.flatMap(child => mapData(child, data.id)))
    : [node];
};

export const processSearchString = (value: string) => value.toLowerCase().replace(/\s/g, '');

// cf. `com.armanta.datamodel.ColDefs`
export const isColumnTypeFloat = (columnType: number) =>
  [
    3, // DOUBLE
    9, // FLOAT
  ].includes(columnType);
