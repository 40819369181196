import { Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { FC } from 'react';
import { LIGHT_BORDER } from '../../shared/constants';

interface Props {
  headerText: string;
  onXClick: () => void;
}

const DrawerHeader: FC<Props> = props => (
  <div
    style={{
      height: '48px',
      display: 'grid',
      gridTemplateColumns: '1fr auto 48px',
      gridTemplateRows: '48px',
      borderBottom: LIGHT_BORDER,
    }}
  >
    <Typography variant='h2' style={{ marginLeft: '12px', alignSelf: 'center' }}>
      {props.headerText}
    </Typography>
    <div style={{ alignSelf: 'center', margin: '0 1rem' }}>{props.children}</div>
    <div
      style={{
        display: 'grid',
        justifyItems: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,103,127,0.05)',
        borderLeft: LIGHT_BORDER,
        cursor: 'pointer',
      }}
      onClick={props.onXClick}
    >
      <Close style={{ color: '#0097a9' }} />
    </div>
  </div>
);

export default DrawerHeader;
