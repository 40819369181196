import { FC, useState } from 'react';
import { FixedSizeList } from 'react-window';
import { NodeType } from '../../shared/constants';
import { Node } from '../../shared/dataTypes';
import { TreeDropdownItem } from './TreeDropdown';
import TreeItem from './TreeItem';

interface Props {
  dataSource: TreeDropdownItem[];
  height: number;
  width: number;
  onItemSelectionChanged: (ids: (string | number)[]) => void;
}

interface HashTable {
  [id: string]: Node<TreeDropdownItem>;
}

const Tree: FC<Props> = props => {
  const allParentIds = Array.from(
    new Set(props.dataSource.filter(d => d.parentId).map(d => d.parentId)),
  );

  const [expanded, setExpanded] = useState<(string | number)[]>(allParentIds);

  // Create hash table in order to quickly look up item by id
  // Making a copy of each item because we are going to mutate the copy below.
  const hashTable: HashTable = {};
  props.dataSource.forEach(item => {
    hashTable[item.id] = { id: item.id, type: NodeType.DETAIL, payload: { ...item } };
  });

  const hasValidParentId = (item: TreeDropdownItem<string | number>) =>
    item.parentId && !!hashTable[item.parentId];

  // Put items in the right order and determine indentation levels. Due to performance issues
  // with large data sets, we eschew some of our usual rules about functional programming
  // and mutating objects.
  const dataTree: Node<TreeDropdownItem> = { id: 'root', type: NodeType.ROOT, children: [] };
  props.dataSource.forEach(item => {
    if (hasValidParentId(item)) {
      if (!hashTable[item.parentId].children) hashTable[item.parentId].children = [];
      hashTable[item.parentId].children.push(hashTable[item.id]);
    } else dataTree.children.push(hashTable[item.id]);
  });

  // TODO check for duplicate IDs and circular parentIds.

  const allAncestorsExpanded = (item: TreeDropdownItem): boolean => {
    if (!hasValidParentId(item)) return true;
    const { parentId } = hashTable[item.id]?.payload;
    return expanded.includes(parentId) && allAncestorsExpanded(hashTable[parentId].payload);
  };

  const isItemChecked = (itemId: string | number): boolean | 'indeterminate' => {
    if (!hashTable[itemId].children?.length) return hashTable[itemId].payload.selected;
    else {
      // Item has children. Whether it's checked depends on whether all, none, or some
      // of its children are checked.
      let returnable: boolean | 'indeterminate';
      let i = 0;

      while (returnable !== 'indeterminate' && i < hashTable[itemId].children.length) {
        const dc = isItemChecked(hashTable[itemId].children[i].id);
        if (returnable === undefined) returnable = dc;
        else if (dc !== returnable) returnable = 'indeterminate';
        i++;
      }
      return returnable;
    }
  };

  const displayItems = flatten(dataTree, 0).filter(allAncestorsExpanded);

  return (
    <FixedSizeList
      className='list-container'
      height={props.height}
      width={props.width}
      itemCount={displayItems.length}
      itemSize={34}
      style={{ overflowX: 'hidden' }}
    >
      {({ style, index }) => {
        if (index === 0) return null;

        const checked = isItemChecked(displayItems[index].id);
        return (
          <TreeItem
            style={style}
            key={index}
            item={displayItems[index]}
            checked={checked === true}
            indeterminate={checked === 'indeterminate'}
            expandable={allParentIds.includes(displayItems[index].id)}
            expanded={expanded.includes(displayItems[index].id)}
            setExpanded={() =>
              setExpanded(
                expanded.includes(displayItems[index].id)
                  ? expanded.filter(e => e !== displayItems[index].id)
                  : [...expanded, displayItems[index].id],
              )
            }
            setChecked={checked =>
              props.onItemSelectionChanged(
                checked
                  ? props.dataSource
                      .filter(
                        d =>
                          d.selected ||
                          d.id === displayItems[index].id ||
                          d.parentId === displayItems[index].id,
                      )
                      .map(d => d.id)
                  : props.dataSource
                      .filter(
                        d =>
                          d.selected &&
                          d.id !== displayItems[index].id &&
                          d.parentId !== displayItems[index].id,
                      )
                      .map(d => d.id),
              )
            }
          />
        );
      }}
    </FixedSizeList>
  );
};

const flatten = (node: Node<TreeDropdownItem>, level: number): TreeDropdownItem[] => {
  const newItem: TreeDropdownItem = { ...node.payload, indentLevel: level };
  return node.children
    ? [newItem, ...node.children.flatMap(c => flatten(c, level + 1))]
    : [newItem];
};

export default Tree;
