import { CSSProperties, FC, ReactElement, useEffect, useState } from 'react';
import CascadingMenuRoot from './CascadingMenuRoot';
import { CascadingMenuItem } from './model';

interface Props {
  showMenu?: boolean;
  items: CascadingMenuItem[];
  icon: ReactElement;
  style?: CSSProperties;
  className?: string;
  onOpen?: () => void;
}

const CascadingMenu: FC<Props> = props => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { onOpen } = props;
  useEffect(() => {
    if (!!anchorEl && onOpen) {
      onOpen();
    }
  }, [anchorEl, onOpen]);
  return (
    <>
      <div
        className={props.className}
        style={{ cursor: 'pointer', ...props.style }}
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        {props.showMenu && props.icon}
      </div>
      <CascadingMenuRoot anchorEl={anchorEl} setAnchorEl={setAnchorEl} items={props.items} />
    </>
  );
};

export default CascadingMenu;
