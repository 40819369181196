import { InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { FC } from 'react';
import { useTableState } from './state/context';

export interface Props {
  disabled?: boolean;
}

const ControlBar: FC<Props> = props => {
  const {
    state: { searchTerm },
    dispatch: { setSearchTerm },
  } = useTableState();

  return (
    <div className='control-bar'>
      <TextField
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        disabled={props.disabled}
        className='search'
        size='small'
        variant='outlined'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Search style={{ color: '#999' }} />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default ControlBar;
