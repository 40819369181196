import moment from 'moment';
import { FC } from 'react';
import './date-picker-month.scss';

interface Props {
  month: moment.Moment;
  onMonthSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
  onYearSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
  currentDate: string;
}

const DatePickerMonth: FC<Props> = props => {
  const currentYear = moment(props.currentDate).year();
  let yearOptions = [];
  for (let i = currentYear - 10; i <= currentYear + 10; i++) {
    yearOptions.push(
      <option key={i} value={i}>
        {i}
      </option>,
    );
  }

  return (
    <div className='date-picker-month-container'>
      <div className='date-picker-month-select-container'>
        <select
          className='date-picker-month-select'
          value={props.month.month()}
          onChange={e => props.onMonthSelect(props.month, e.target.value)}
        >
          {moment.months().map((label, value) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div className='date-picker-month-select-container'>
        <select
          className='date-picker-month-select'
          value={props.month.year()}
          onChange={e => props.onYearSelect(props.month, e.target.value)}
        >
          {yearOptions}
        </select>
      </div>
    </div>
  );
};

export default DatePickerMonth;
