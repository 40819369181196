import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import { FC, ReactNode, useCallback, useState } from 'react';
import { BaseButtonProps } from '../../shared/dataTypes';
import './dropdown-action-button.scss';

export interface Props {
  buttonComponent: ReactNode;
  menuButtonProps: MenuItemProps[];
  disabled?: boolean;
}

export interface MenuItemProps extends BaseButtonProps {
  closeMenu?: boolean;
}

const DropdownActionButton: FC<Props> = props => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const createMenuItem = useCallback(
    ({ label, onClick, disabled, closeMenu = true }: MenuItemProps) => (
      <MenuItem
        key={label}
        onClick={() => {
          onClick();

          if (closeMenu) {
            setAnchorEl(null);
          }
        }}
        {...{ disabled }}
      >
        {label}
      </MenuItem>
    ),
    [],
  );

  return (
    <Box boxShadow={3} className={`dropdown-action-button ${props.disabled ? 'disabled' : ''}`}>
      {props.buttonComponent}

      <div className='button-middle' />

      <div className='button-right'>
        <IconButton
          className='button-right-icon-container'
          color='primary'
          onClick={event => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <ArrowDropDownIcon className='button-right-icon' />
        </IconButton>

        <Menu
          {...{ anchorEl }}
          open={!!anchorEl}
          onClose={closeMenu}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          {props.menuButtonProps.map(createMenuItem)}
        </Menu>
      </div>
    </Box>
  );
};

export default DropdownActionButton;
