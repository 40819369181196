import { Component } from 'react';
import ReactDOM from 'react-dom';
import { Input } from '@material-ui/core';

class CellEditor extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { cellValue: props.value };
  }

  getValue() {
    return { value: this.state.cellValue };
  }

  getInputNode() {
    return (ReactDOM.findDOMNode(this) as HTMLInputElement).getElementsByTagName('input')[0];
  }

  handleChangeComplete = (event: any) => {
    // this.setState({ cellValue }, () => this.props.onCommit());
    this.setState({ cellValue: event.target.value });
  };

  render() {
    return <Input fullWidth value={this.state.cellValue} onChange={this.handleChangeComplete} />;
  }
}

export default CellEditor;
