import { RowField } from '../../shared/constants';
import { extractLeaves, getTextAlign } from '../../shared/dataConverters';
import { ReportRawData, Sort } from '../../shared/dataTypes';
import { mapData } from '../../shared/utils';
import { SortOrder } from '../Table/model';
import { ReportColumn, ReportDatum } from './model';

export const VALUE_KEY_CHAR_ID = 1046;

export const transformReport = ({
  headers,
  data: rawData,
}: Pick<ReportRawData, 'headers' | 'chars' | 'data'>) => {
  const columns = extractLeaves(headers).map<ReportColumn>(header => {
    const id = header.props.columnKeyValue;

    return {
      id,
      header: header.name,
      getValue: datum => datum.chars[`${id}0`],
      textAlign: getTextAlign(null, header.props.dataType),
    };
  });

  const data = mapData(rawData, 0).map(({ payload }) =>
    payload.reduce<ReportDatum>(
      (datum, nextCell) => ({
        ...datum,
        chars: {
          ...datum.chars,
          [nextCell[RowField.COL_HASH]]: nextCell[RowField.FORMATTED_VAL],
        },
      }),
      {
        id: payload.find(cell => cell[RowField.COL_HASH] === VALUE_KEY_CHAR_ID * 10)[
          RowField.FORMATTED_VAL
        ],
        chars: {},
      },
    ),
  );

  return {
    columns,
    data,
  };
};

export const transformSort = ({ charId, direction }: Sort): SortOrder => ({
  columnId: charId,
  direction,
});
